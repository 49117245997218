export const menuItems = [{
    id: 1,
    label: "menuitems.menu.text",
    isTitle: true
},
{
    id: 2,
    label: "menuitems.dashboards.text",
    icon: "bx-home-circle",
    link: "/",

},


// {
//     id: 7,
//     isLayout: true
// },
// {
//     id: 8,
//     label: "menuitems.crypto.text",
//     isTitle: true
// },
{
    id: 9,
    label: "menuitems.crypto.list.wallet",
    link: "/crypto/wallet",
    icon: "bx-wallet",
},
// {
//     id: 10,
//     label: "menuitems.crypto.list.buy/sell",
//     link: "/crypto/buy-sell",
//     icon: "bx bx-sort",
// },
// {
//     id: 11,
//     label: "menuitems.crypto.list.pump_dump",
//     link: "/crypto/pump_dump",
//     icon: "bx bx-line-chart",
// },

{
    id: 13,
    label: "menuitems.crypto.list.orders",
    link: "/crypto/orders",
    icon: "bx bx-server",
},

{
    id: 16,
    label: "menuitems.crypto.list.users",
    link: "/users/list",
    icon: "bx-user",
},
{
    id: 17,
    label: "menuitems.crypto.list.listcrypto",
    link: "/crypto",
    icon: "bx-dollar",
},

// {
//     id: 18,
//     label: "menuitems.crypto.list.wallet_transfer",
//     link: "/crypto/wallettransactions",
//     icon: "bx-wallet",
// },
// {
//     id: 19,
//     label: "menuitems.crypto.list.add_crypto",
//     link: "/users/addCrypto",
//     icon: "bx-user",
// },


// {
//     id: 29,
//     label: "Setting",
//     icon: "bx-envelope",
//     subItems: [{
//             id: 30,
//             label: "Company Setting",
//             link: "/setting/company",
//             parentId: 29
//         },
//         {
//             id: 31,
//             label: "Email Setting",
//             link: "/setting/email",
//             parentId: 29
//         },
//         {
//             id: 32,
//             label: "SMS Setting",
//             link: "/setting/sms",
//             parentId: 29
//         },
//         {
//             id: 33,
//             label: "Payment Setting",
//             link: "/setting/payment",
//             parentId: 29
//         },
//         {
//             id: 34,
//             label: "Notification Setting",
//             link: "/setting/notification",
//             parentId: 29
//         },
//         {
//             id: 34,
//             label: "Recaptcha Setting",
//             link: "/setting/recapta",
//             parentId: 29
//         },


//     ]
// },

{
    id: 35,
    label: "menuitems.crypto.list.kyc_verify",
    icon: "bx bxs-user-check",
    subItems: [{
        id: 36,
        label: "menuitems.crypto.list.completed",
        link: "/kyc/completed",
        parentId: 35
    },
    {
        id: 37,
        label: "menuitems.crypto.list.rejected",
        link: "/kyc/rejected",
        parentId: 35
    },
    {
        id: 38,
        label: "menuitems.crypto.list.pending",
        link: "/kyc/pending",
        parentId: 35
    }
    ]
},
{
    id: 39,
    label: "menuitems.crypto.list.report",
    icon: "bx bxs-report",
    subItems: [
        {
            id: 41,
            label: "menuitems.crypto.list.trading_report",
            link: "/report/fee",
            parentId: 39
        },
        {
            id: 42,
            label: "menuitems.crypto.list.client_report",
            link: "/report/client",
            parentId: 39
        },
        {
            id: 67,
            label: "Deposit Report",
            link: "/report/deposit",
            parentId: 39
        },
        {
            id: 40,
            label: "Withdrawal Report",
            link: "/report/wallet",
            parentId: 39
        },
        // {
        //     id: 68,
        //     label: "Withdrawal Report",
        //     link: "/report/withdrawal",
        //     parentId: 39
        // }
    ]
},

{
    id: 43,
    label: "menuitems.crypto.list.bank_verify",
    icon: "bx bxs-bank",
    subItems: [{
            id: 44,
            label: "menuitems.crypto.list.completed",
            link: "/bank/completed",
            parentId: 43
        },
        {
            id: 45,
            label: "menuitems.crypto.list.rejected",
            link: "/bank/rejected",
            parentId: 43
        },
        {
            id: 46,
            label: "menuitems.crypto.list.pending",
            link: "/bank/pending",
            parentId: 43
        }
    ]
},

// {
//     id: 47,
//     label: "menuitems.crypto.list.upi_verify",
//     icon: "bx bxl-google",
//     subItems: [{
//             id: 48,
//             label: "menuitems.crypto.list.completed",
//             link: "/upi/completed",
//             parentId: 47
//         },
//         {
//             id: 49,
//             label: "menuitems.crypto.list.rejected",
//             link: "/upi/rejected",
//             parentId: 47
//         },
//         {
//             id: 50,
//             label: "menuitems.crypto.list.pending",
//             link: "/upi/pending",
//             parentId: 47
//         }
//     ]
// },
// {
//     id: 47,
//     label: "P2P",
//     icon: "bx bxl-google",
//     subItems: [

//         {
//             id: 99,
//             label: "Create Order",
//             link: "/p2p/ordercreate",
//             parentId: 47
//         },
//         {
//             id: 48,
//             label: "Orders",
//             link: "/p2p/allorders",
//             parentId: 47
//         },
//         {
//             id: 49,
//             label: "chat-list",
//             link: '/p2p/chat-list',
//             parentId: 47
//         },
//         {
//             id: 50,
//             label: "wallet transaction list",
//             link: "/p2p/wallet-transaction",
//             parentId: 47
//         },
//         // {
//         //     id: 49,
//         //     label: "menuitems.crypto.list.rejected",
//         //     link: "/upi/rejected",
//         //     parentId: 47
//         // },
//         // {
//         //     id: 50,
//         //     label: "menuitems.crypto.list.pending",
//         //     link: "/upi/pending",
//         //     parentId: 47
//         // }
//     ]
// },

{
    id: 51,
    label: "menuitems.crypto.list.list_coin",
    icon: "bx bx-list-ol",
    subItems: [{
        id: 52,
        label: "menuitems.crypto.list.list_coin",
        link: "/list_crypto/listedcoin",
        parentId: 51
    },
    {
        id: 53,
        label: "menuitems.crypto.list.add_coin",
        link: "/list_crypto/addcoin",
        parentId: 51
    },
    ]
},

{
    id: 54,
    label: "menuitems.crypto.list.page",
    icon: "bx bxs-file-html",
    subItems: [{
        id: 55,
        label: "menuitems.crypto.list.page_list",
        link: "/page/list",
        parentId: 54
    }]
},
{


    id: 117,
    label: "Staking",
    icon: "dripicons-stack",
    subItems: [{
      id: 118,
      label: "Stake Plan List",
      link: "/Staking/list",
      parentId: 117,
    },
    {
      id: 119,
      label: "Stake Create",
      link: "/staking/create",
      parentId: 117,
    },
    ]
},


// {
//     id: 56,
//     label: "menuitems.crypto.list.liquidity",
//     icon: "bx bxs-hourglass-top",
//     subItems: [{
//         id: 57,
//         label: "menuitems.crypto.list.liquidity_list",
//         link: "/liquidity/list",
//         parentId: 56
//     },
//     {
//         id: 58,
//         label: "menuitems.crypto.list.liquidity_add",
//         link: "/liquidity/create",
//         parentId: 56
//     },
//     ]
// },

{
    id: 59,
    label: "menuitems.template.text",
    icon: "bx bx-send",
    subItems: [{
        id: 60,
        label: "menuitems.template.list.templates",
        link: "/template/list",
        parentId: 59
    },
    {
        id: 61,
        label: "menuitems.template.list.templatecreate",
        link: "/template/create",
        parentId: 59
    },
    ]
},
// {
//     id: 62,
//     label: "Vendor",
//     icon: "bx bx-user-voice",
//     subItems: [{
//         id: 63,
//         label: "Vendor List",
//         link: "/vendor/list",
//         parentId: 62
//     }]
// },

// {
//     id: 64,
//     label: "Broker",
//     icon: "bx bx-group",
//     subItems: [{
//         id: 65,
//         label: "Broker List",
//         link: "/broker/list",
//         parentId: 64
//     },

//     ]
// },

// {
//     id: 65,
//     label: "Forex Transfer",
//     icon: "bx bx-transfer",
//     subItems: [{
//         id: 66,
//         label: "Forex Transfer List",
//         link: "/forextransfer/list",
//         parentId: 65
//     },

//     ]
// },
// {

    
//     id: 117,
//     label: "Staking",
//     icon: "dripicons-stack",
//     subItems: [
//       {
//         id: 118,
//         label: "Stake Plan List",
//         link: "/Staking/list",
//         parentId: 117,
//       },
//       {
//         id: 119,
//         label: "Stake Create",
//         link: "/staking/create",
//         parentId: 117,
//       },
//        {
//             id: 120,
//             label: "User Stake List",
//             link: "/user_stake/list",
//             parentId: 117,
//         },
//     ],
// },

{
    id: 127,
    label: "menuitems.crypto.list.banner",
    icon: "bx bxs-image-add",
    subItems: [{
        id: 128,
        label: "menuitems.crypto.list.banner_add",
        link: "/banner/create",
        parentId: 127
    },
    {
        id: 129,
        label: "menuitems.crypto.list.banner_list",
        link: "/banner/list",
        parentId: 127
    },

    ]
},
{
    id: 130,
    label: "menuitems.crypto.list.support",
    icon: "bx bx-support",
    subItems: [{
        id: 131,
        label: "menuitems.crypto.list.support_category",
        link: "/support/category",
        parentId: 130
    },
    {
        id: 132,
        label: "menuitems.crypto.list.ticket_list",
        link: "/support/ticket-list",
        parentId: 130
    },

    ]
},
// {
//     id: 133,
//     label: "menuitems.crypto.list.sub-admin",
//     link: "/sub-admin/list",
//     icon: "bx-user",

// },
{
    id: 134,
    label: "menuitems.crypto.list.currencies",
    link: "/currency/currencylist",
    icon: "bx bx-list-ol",

},
// {
//     id: 135,
//     label: "menuitems.notification.text",
//     icon: "bx bx-send",
//     subItems: [{
//         id: 136,
//         label: "menuitems.notification.list.notifications",
//         link: "/notification/list",
//         parentId: 135
//     },
//     {
//         id: 137,
//         label: "menuitems.notification.list.notificationcreate",
//         link: "/notification/create",
//         parentId: 135
//     },
//     ]
// },

// {
//     id: 138,
//     label: "menuitems.crypto.list.token_request",
//     link: "/request/tokens",
//     icon: "bx bx-server",
// },

// {
//     id: 139,
//     label: "menuitems.no_commission.text",
//     icon: "bx bx-send",
//     subItems: [{
//         id: 140,
//         label: "menuitems.no_commission.list.commission",
//         link: "/no-commission/list",
//         parentId: 139
//     },
//     {
//         id: 141,
//         label: "menuitems.no_commission.list.commission_create",
//         link: "/no-commission/create",
//         parentId: 139
//     },
//     ]
// },

{
    id: 142,
    label: "menuitems.settings.text",
    link: "/settings",
    icon: "bx bx-server",
},
{
    id: 143,
    label: "menuitems.launchpad.text",
    icon: "bx bx-sticker",
    subItems: [
      {
        id: 144,
        label: "menuitems.launchpad.list.tokenCreate",
        link: "/create/token",
        parentId: 143,
      },
      {
        id: 144,
        label: "menuitems.launchpad.list.roundCreate",
        link: "/create/round",
        parentId: 143,
      },
      // {
      //   id: 146,
      //   label: "menuitems.launchpad.list.tokenGet", 
      //   link: "/get/token",
      //   parentId: 143,
      // },
      {
        id: 147,
        label: "menuitems.launchpad.list.token_get",
        link: "/get/tokens",
        parentId: 143,
      },
    ],
  },
{
    id: 122,
    label: "menuitems.ico.text",
    icon: "bx bx-sticker",
    subItems: [
      {
        id: 144,
        label: "menuitems.ico.list.roundCreate",
        link: "/ico/round",
        parentId: 122,
      },
      {
        id: 144,
        label: "menuitems.ico.list.bir_bal",
        link: "/ico/balances",
        parentId: 122,
      },
      {
        id: 144,
        label: "menuitems.ico.list.claim_history",
        link: "/ico/claim_history",
        parentId: 122,
      },
    ],
  },


];